
th, td {
    border: 1px solid lightgray;
    padding: 0.15rem;
}

.mainSpinner {
    position: fixed;
    left: 10%;
    right: 10%;
    bottom: 4rem;
    /* display: none; */
}

.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}
 
.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}
 
.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}
 
.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}
 
.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}
 
.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}

@media all and (max-width: 768px) {
    #settings {
        max-height: 30vh;
    }
}

@media not all and (max-width: 768px) {
    #settings {
        float: right;
        max-width: 30vw;
        /* for some reason the cy canvas can't avoid this float */
        overflow: scroll;
        max-height: calc(50vh - 4.75rem)
    }
}
